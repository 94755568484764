<!--品牌馆-->
<template>
  <div class="brandDiv">
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="brandContent">
      <div class="brandList" v-for="(item, index) in brandList" :key="index" @click="toDetail(item)">
        <div class="brandImg">
          <img :src="item.brandImgUrl" alt="">
        </div>
        <div class="brandName">
          <div class="title">{{item.brandName}}</div>
        </div>
      </div>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "brandPavilion",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
          path: ''
        },
        {
          name: '品牌馆展示',
          path: '/richPeasant/brandPavilion'
        },
      ],
      brandList: []
    }
  },
  mounted() {
    this.getBrandData()
  },
  methods: {
    //查询品牌馆数据
    getBrandData() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/brandMuseum/hussarQuery',
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.brandList = res.data.data.data
        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
    //点击跳转地标产品
    toDetail(item) {
      let city = item.reginName
      this.$router.push({
        path: '/richPeasant/index',
        query: {
          city: city
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .brandDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .brandContent {
    background: #FFFFFF;
    min-height: 409px;
    width: 1120px;
    margin: auto;
    padding: 32px 38px 12px 38px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .brandList {
      height: 350px;
      margin: 0px 8px;
      .brandImg {
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
        border-radius: 8px;
        img{
          width: 264px;
          height: 327px;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.04);
          border-radius: 8px;
        }
      }
      .brandName {
        position: relative;
        bottom: 54px;
        width: 264px;
        height: 50px;
        background: rgba(80,142,255,0.47);
        border-radius: 0px 0px 8px 8px;
        backdrop-filter: blur(2px);
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 50px;
        .title {

        }
      }
    }
    .brandList:hover {
      cursor: pointer;
    }
  }
</style>
